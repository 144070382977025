import { css, styled } from "../../../../../styles";

export const DisplayTitle = styled("div", {
  marginLeft: "7.5px",

  fontFamily: "$openSans",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
});

export const DisplaySubTitle = styled("div", {
  fontFamily: "$openSans",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
});

export const DisplayRulescontainer = styled("div", {
  marginTop: "44px",
  padding: "38px 48px",

  display: "flex",
  flexDirection: "column",
  rowGap: "50px",

  backgroundColor: "rgba(248, 248, 248, 0.8);",

  borderRadius: "8px",
});

export const MultipleInputsRow = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",

  columnGap: "40px",
});

export const RowElement = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",

  gap: "10px",
});

export const RangeSettingsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",

  gap: "4px",
});

export const ErrorMessage = styled("p", {
  top: "70px",
  position: "absolute",

  fontSize: "12px !important",
  fontWeight: "600 !important",
  whiteSpace: "pre-line",

  color: "$error-light !important",

  maxWidth: "160px",
  width: "max-content !important",
});

export const WarningMessage = styled(ErrorMessage, {
  maxWidth: "290px",
  color: "$warning-darker !important",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});
// CSS

export const AddRangeOptionButton = css({
  button: {
    backgroundColor: "$gray-50 !important",
    borderColor: "$white !important",

    p: {
      color: "$charcoal !important",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$gray-60 !important",
    },

    "&:focus-visible": {
      outline: "2px solid $primary-30 !important",
    },
  },
});

export const selectCss = css({
  width: "320px !important",
  height: "41px",

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 2px $gray-50 !important",
    boxShadow: "0 0 0 1px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },

    ".ant-select-selection-search-input": {
      height: "100% !important",
    },
  },
});

export const smallSelectCss = css(selectCss, {
  width: "68px !important",

  "ant-select-item-option-content": {
    fontFamily: "monospace !important",
  },
});

export const selectPopUpCss = css({
  "ant-select-item-option-content": {
    fontFamily: "monospace !important",
  },
});

export const mediumTextFieldClassName = css({
  width: "140px !important",

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },

    "&:focus-visible": {
      border: "2px solid $primary-30",
    },
  },
});

export const smallTextFieldClassName = css(mediumTextFieldClassName, {
  width: "98px !important",
});

export const infoTextFieldClassName = css(mediumTextFieldClassName, {
  width: "700px !important",
});

export const datePickerClassName = css({
  minHeight: "41px",
  minWidth: "140px",
  justifyContent: "flex-start",

  ".ant-color-picker-color-block": {
    alignSelf: "center",
    marginLeft: "10px",
  },

  "&.ant-color-picker-trigger-active": {
    borderColor: "$primary-60 !important",
  },

  "&:hover": {
    border: "2px solid $primary-60",
  },
});

export const deleteRangeOptionButtonClassName = css({
  alignSelf: "end",
  marginBottom: "6px",
  marginLeft: "-16px",
});
