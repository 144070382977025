import { ColorPicker } from "antd";
import { Form, Formik, FormikProps, getIn } from "formik";
import { forwardRef, useEffect } from "react";
import * as yup from "yup";

import {
  IRangeOption,
  ISurveyDisplayRules,
} from "../../../../../@types/Surveys";
import { Close } from "../../../../../assets/icons/Close";
import { Button } from "../../../../../components/Button";
import { CustomIconButton } from "../../../../../components/CustomIconButton";
import { SelectDropdown } from "../../../../../components/SelectDropdown";
import { TextField } from "../../../../../components/TextField";
import { GraphTypes } from "../../../../../constants/surveys";
import { getRandomHexColor } from "../../../../../utils/colorGenerator";
import { IDisplayRulesOptions } from "../../SurveyBuilder";
import {
  DisplayRulescontainer,
  DisplaySubTitle,
  DisplayTitle,
  MultipleInputsRow,
  RowElement,
  selectCss,
  mediumTextFieldClassName,
  infoTextFieldClassName,
  AddRangeOptionButton,
  datePickerClassName,
  deleteRangeOptionButtonClassName,
  smallTextFieldClassName,
  smallSelectCss,
  RangeSettingsContainer,
  selectPopUpCss,
  ErrorMessage,
  SubmitContainer,
  WarningMessage,
} from "./DisplayTab.styles";

type RangeOptionFormik = Partial<IRangeOption>;

interface ISurveyDisplayBaseProps {
  displayRulesOptions: IDisplayRulesOptions;
  hasScoreStrategy: boolean;
}

interface ISurveyDisplayFormikProps extends ISurveyDisplayBaseProps {
  displayRules: ISurveyDisplayRules;
}

interface ISurveyDisplayProps extends ISurveyDisplayBaseProps {
  displaySettingsFormik: FormikProps<ISurveyDisplayRules>;
}

function SurveyBuilderDisplayTab({
  displayRulesOptions,
  displaySettingsFormik,
  hasScoreStrategy,
}: ISurveyDisplayProps) {
  const defaultDisplayOption = {
    start: undefined,
    end: undefined,
    color: getRandomHexColor(),
    name: undefined,
    startComparator: undefined,
    endComparator: undefined,
  };

  const shouldShowErrorMessage = (
    field:
      | "yAxisMin"
      | "yAxisMax"
      | "start"
      | "startComparator"
      | "end"
      | "endComparator"
      | "name"
      | "color",
    index?: number
  ) => {
    if (
      index !== undefined &&
      Array.isArray(displaySettingsFormik.touched.ranges) &&
      displaySettingsFormik.touched.ranges?.[index]
    ) {
      if (
        (field === "start" || field === "end") &&
        displaySettingsFormik.touched.ranges?.[index][field]
      ) {
        const valueError = getIn(
          displaySettingsFormik.errors.ranges?.[index],
          field
        );

        const comparatorError = getIn(
          displaySettingsFormik.errors.ranges?.[index],
          `${field}Comparator`
        );

        return `${comparatorError ?? ""}\n${valueError ?? ""}`.trim();
      }
      return displaySettingsFormik.touched.ranges?.[index][field]
        ? getIn(displaySettingsFormik.errors.ranges?.[index], field)
        : "";
    }

    if (field === "yAxisMin" || field === "yAxisMax")
      return displaySettingsFormik.touched[field] ||
        displaySettingsFormik.touched.ranges
        ? getIn(displaySettingsFormik.errors, field)
        : "";

    return "";
  };

  const addRangeOption = () => {
    displaySettingsFormik.setFieldValue("ranges", [
      ...(displaySettingsFormik.values.ranges ?? []),
      defaultDisplayOption,
    ]);
  };

  const deleteRangeOption = (indexToRemove: number) => {
    displaySettingsFormik.setFieldValue(
      "ranges",
      displaySettingsFormik.values.ranges?.filter(
        (_, index) => index !== indexToRemove
      )
    );
  };

  const cleanEndValues = (index: number) => {
    displaySettingsFormik.setFieldValue(`ranges[${index}].end`, undefined);
    displaySettingsFormik.setFieldValue(
      `ranges[${index}].endComparator`,
      undefined
    );
  };

  useEffect(() => {
    const updatedDisplayRules = displaySettingsFormik.values;
    if (updatedDisplayRules.type === GraphTypes.tableView) {
      updatedDisplayRules.yAxisMin = undefined;
      updatedDisplayRules.yAxisMax = undefined;
      updatedDisplayRules.ranges = undefined;
    }
    displaySettingsFormik.setValues(updatedDisplayRules);
  }, [displaySettingsFormik.values]);

  return (
    <>
      <DisplayTitle>Display rules</DisplayTitle>
      <DisplayRulescontainer>
        <MultipleInputsRow>
          <RowElement>
            <DisplaySubTitle>Graph type *</DisplaySubTitle>
            <SelectDropdown
              className={selectCss()}
              width={248}
              height={42}
              showSearch
              optionFilterProp="label"
              value={displaySettingsFormik.values.type}
              onValueChange={(value) => {
                displaySettingsFormik.setFieldValue("type", value.toString());
                if (
                  value.toString() === GraphTypes.totalScoreGraphWithLegend &&
                  !displaySettingsFormik.values.ranges?.length
                ) {
                  addRangeOption();
                }
              }}
              options={displayRulesOptions.displayRulesTypes}
            />
            {!hasScoreStrategy &&
              displaySettingsFormik.values.type !== GraphTypes.tableView && (
                <WarningMessage>
                  Please add a score strategy in the Content tab
                </WarningMessage>
              )}
          </RowElement>
          {displaySettingsFormik.values.type ===
            GraphTypes.totalScoreGraphWithLegend && (
            <>
              <RowElement>
                <DisplaySubTitle>Y-axis min *</DisplaySubTitle>
                <TextField
                  label=""
                  placeholder="min"
                  name="yAxisMin"
                  value={displaySettingsFormik.values.yAxisMin}
                  onChange={displaySettingsFormik.handleChange}
                  className={mediumTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
                <ErrorMessage>
                  {shouldShowErrorMessage("yAxisMin")}{" "}
                </ErrorMessage>
              </RowElement>
              <RowElement>
                <DisplaySubTitle>Y-axis max *</DisplaySubTitle>
                <TextField
                  label=""
                  placeholder="max"
                  name="yAxisMax"
                  value={displaySettingsFormik.values.yAxisMax}
                  onChange={displaySettingsFormik.handleChange}
                  className={mediumTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
                <ErrorMessage>
                  {shouldShowErrorMessage("yAxisMax")}{" "}
                </ErrorMessage>
              </RowElement>
            </>
          )}
        </MultipleInputsRow>
        <RowElement>
          <DisplaySubTitle>HCP Information</DisplaySubTitle>
          <TextField
            label=""
            placeholder="Add information or definition that is available on the portal"
            name="hcpInformation"
            value={displaySettingsFormik.values.hcpInformation}
            onChange={displaySettingsFormik.handleChange}
            className={infoTextFieldClassName()}
            backgroudColor="white"
          />
        </RowElement>
        <RowElement>
          <DisplaySubTitle>Patient Information</DisplaySubTitle>
          <TextField
            label=""
            placeholder="Add information or definition that is available on the patient app"
            name="patientInformation"
            value={displaySettingsFormik.values.patientInformation}
            onChange={displaySettingsFormik.handleChange}
            className={infoTextFieldClassName()}
            backgroudColor="white"
          />
        </RowElement>

        {displaySettingsFormik.values.ranges !== undefined &&
          displaySettingsFormik.values.ranges.map((range, index) => (
            <MultipleInputsRow key={index}>
              <RowElement>
                <DisplaySubTitle>Start Range *</DisplaySubTitle>
                <RangeSettingsContainer>
                  <SelectDropdown
                    className={smallSelectCss()}
                    popUpCsss={selectPopUpCss()}
                    width={248}
                    height={42}
                    showSearch
                    optionFilterProp="label"
                    value={
                      displaySettingsFormik.values.ranges?.[index]
                        .startComparator
                    }
                    onValueChange={(value) => {
                      displaySettingsFormik.setFieldValue(
                        `ranges[${index}].startComparator`,
                        value.toString()
                      );

                      if (value.toString() === "=") {
                        cleanEndValues(index);
                      }
                    }}
                    options={displayRulesOptions.startComparators}
                  />
                  <TextField
                    label=""
                    placeholder="min"
                    name={`ranges[${index}].start`}
                    value={displaySettingsFormik.values.ranges?.[index].start}
                    onChange={displaySettingsFormik.handleChange}
                    className={smallTextFieldClassName()}
                    backgroudColor="white"
                    onBlur={displaySettingsFormik.handleBlur}
                  />
                </RangeSettingsContainer>
                <ErrorMessage>
                  {shouldShowErrorMessage("start", index)}
                </ErrorMessage>
              </RowElement>
              <RowElement>
                <DisplaySubTitle>End Range *</DisplaySubTitle>
                <RangeSettingsContainer>
                  <SelectDropdown
                    disabled={range.startComparator === "="}
                    className={smallSelectCss()}
                    popUpCsss={selectPopUpCss()}
                    width={248}
                    height={42}
                    showSearch
                    optionFilterProp="label"
                    value={
                      displaySettingsFormik.values.ranges !== undefined
                        ? displaySettingsFormik.values.ranges[index]
                            .endComparator
                        : ""
                    }
                    onValueChange={(value) => {
                      displaySettingsFormik.setFieldValue(
                        `ranges[${index}].endComparator`,
                        value.toString()
                      );
                    }}
                    options={displayRulesOptions.endComparators}
                  />
                  <TextField
                    disabled={range.startComparator === "="}
                    label=""
                    placeholder="max"
                    name={`ranges[${index}].end`}
                    value={
                      displaySettingsFormik.values.ranges?.[index].end ?? ""
                    }
                    onChange={displaySettingsFormik.handleChange}
                    className={smallTextFieldClassName()}
                    backgroudColor="white"
                    onBlur={displaySettingsFormik.handleBlur}
                  />
                </RangeSettingsContainer>
                <ErrorMessage>
                  {shouldShowErrorMessage("end", index)}
                </ErrorMessage>
              </RowElement>
              <RowElement>
                <DisplaySubTitle>Color *</DisplaySubTitle>
                <ColorPicker
                  className={datePickerClassName()}
                  value={displaySettingsFormik.values.ranges?.[index].color}
                  onChange={(value) => {
                    displaySettingsFormik.setFieldValue(
                      `ranges[${index}].color`,
                      value.toHexString()
                    );
                  }}
                  disabledFormat
                  showText
                  defaultValue="black"
                  disabledAlpha
                />
                <ErrorMessage>
                  {shouldShowErrorMessage("color", index)}
                </ErrorMessage>
              </RowElement>
              <RowElement>
                <DisplaySubTitle>Name *</DisplaySubTitle>
                <TextField
                  label=""
                  placeholder="name"
                  name={`ranges[${index}].name`}
                  value={displaySettingsFormik.values.ranges?.[index].name}
                  onChange={displaySettingsFormik.handleChange}
                  className={mediumTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
                <ErrorMessage>
                  {shouldShowErrorMessage("name", index)}
                </ErrorMessage>
              </RowElement>
              {(displaySettingsFormik.values.ranges?.length ?? 0) > 1 && (
                <CustomIconButton
                  className={deleteRangeOptionButtonClassName()}
                  key={index}
                  onClick={() => deleteRangeOption(index)}
                >
                  <Close />
                </CustomIconButton>
              )}
            </MultipleInputsRow>
          ))}
        {displaySettingsFormik.values.type ===
          GraphTypes.totalScoreGraphWithLegend && (
          <SubmitContainer>
            <Button
              type="button"
              label="Add range"
              className={AddRangeOptionButton()}
              onClick={addRangeOption}
            />
            <ErrorMessage style={{ top: "auto", position: "relative" }}>
              {typeof displaySettingsFormik.errors.ranges === "string"
                ? displaySettingsFormik.errors.ranges
                : ""}
            </ErrorMessage>
          </SubmitContainer>
        )}
      </DisplayRulescontainer>
    </>
  );
}

export const DisplaySettingsFormik = forwardRef<
  FormikProps<ISurveyDisplayRules>,
  ISurveyDisplayFormikProps
>(({ displayRules, displayRulesOptions, hasScoreStrategy }, ref) => {
  const handleOnSubmitForm = () => {};

  const checkOverlap = (
    range1: RangeOptionFormik,
    range2: RangeOptionFormik
  ) => {
    if (
      range1.start === undefined ||
      range1.startComparator === undefined ||
      range1.end === undefined ||
      range1.endComparator === undefined ||
      range2.start === undefined ||
      range2.startComparator === undefined
    ) {
      return false;
    }

    if (
      range1.start === range2.start &&
      !(
        (range1.startComparator === ">" && range2.startComparator === "=") ||
        (range1.startComparator === "=" && range2.startComparator === ">")
      )
    ) {
      return true;
    }
    if (range1.startComparator === "=" && range2.startComparator !== "=") {
      return false;
    }

    if (range1.startComparator === "=" && range2.startComparator === "=") {
      return false;
    }

    if (
      range2.start < range1.end ||
      (range2.start === range1.end &&
        range1.endComparator.includes("=") &&
        range2.startComparator !== ">")
    ) {
      return true;
    }
    return false;
  };

  const rangesValidationSchema = yup.object().shape({
    start: yup.number().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .number()
        .typeError("Value must be a number")
        .lessThan(yup.ref("$yAxisMax"), "Value must be smaller than Y-axis max")
        .min(yup.ref("$yAxisMin"), "Value can't be smaller than Y-axis min")
        .required("Value required"),
      otherwise: yup.number(),
    }),

    end: yup.number().when(["$type", "startComparator"], {
      is: (type: string, startComparator: string) =>
        type === GraphTypes.totalScoreGraphWithLegend &&
        startComparator !== "=",
      then: yup
        .number()
        .typeError("Value must be a number")
        .moreThan(yup.ref("$yAxisMin"), "Value must be bigger than Y-axis min")
        .max(yup.ref("$yAxisMax"), "Value can't be bigger than Y-axis max")
        .moreThan(yup.ref("start"), "Value has to be greater than start value")
        .required("Value required"),
      otherwise: yup.number(),
    }),

    startComparator: yup.string().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup.string().required("Comparator required"),
      otherwise: yup.string(),
    }),

    endComparator: yup.string().when(["$type", "startComparator"], {
      is: (type: string, startComparator: string) =>
        type === GraphTypes.totalScoreGraphWithLegend &&
        startComparator !== "=",
      then: yup.string().required("Comparator required"),
      otherwise: yup.string(),
    }),

    color: yup.string().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup.string().required("Color required"),
      otherwise: yup.string(),
    }),

    name: yup.string().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup.string().required("Name required"),
      otherwise: yup.string(),
    }),
  });

  const formValidationSchema = yup.object().shape({
    type: yup.string(),
    hcpInformation: yup.string(),
    patientInformation: yup.string(),
    yAxisMin: yup.number().when("type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .number()
        .typeError("Must be a number")
        .min(0, "Y-axis min can't be negative")
        .required("Required"),
      otherwise: yup.number().nullable(),
    }),
    yAxisMax: yup.number().when("type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .number()
        .typeError("Must be a number")
        .moreThan(0, "Must be greater than 0")
        .moreThan(yup.ref("yAxisMin"), "Must be bigger than Y-axis min")
        .required("Required"),
      otherwise: yup.number().nullable(),
    }),
    ranges: yup.array().when("type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .array()
        .of(rangesValidationSchema)
        .required("s")
        .min(1, "At least one range option required")
        .test(
          "no-overlapping-ranges",
          "Intervals cannot overlap",
          function overlapTest(ranges) {
            if (!ranges || ranges.length < 2) return true;

            const validRanges = ranges.filter(
              (range) =>
                typeof range.start === "number" && typeof range.end === "number"
            );

            if (validRanges.length < 2) return true;

            const sortedRanges = [...validRanges].sort(
              (a, b) => (a.start ?? 0) - (b.start ?? 0)
            );

            for (let i = 0; i < sortedRanges.length - 1; i += 1) {
              const current = sortedRanges[i];
              const next = sortedRanges[i + 1];

              if (checkOverlap(current, next)) {
                return false;
              }
            }
            return true;
          }
        ),
      otherwise: yup.array(),
    }),
  });
  return (
    <Formik<ISurveyDisplayRules>
      innerRef={ref}
      initialValues={displayRules}
      onSubmit={handleOnSubmitForm}
      validationSchema={formValidationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Form>
          <SurveyBuilderDisplayTab
            hasScoreStrategy={hasScoreStrategy}
            displaySettingsFormik={formik}
            displayRulesOptions={displayRulesOptions}
          />
        </Form>
      )}
    </Formik>
  );
});
